import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable'

export default function Image() {
    let navigate = useNavigate()
    const role = JSON.parse(localStorage.getItem("roledata"))

    const addImage = () => {
        navigate('/addimage');
    }
    return (
        <Box className='companies'>
            <MiniDrawer menuOrder={6} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Images</Typography>
                    {role.UserRoles == null ? <Button variant="contained" className='addbtn' onClick={() => addImage()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Image</Button>
                        : role.UserRoles.Images.AddImages && <Button variant="contained" className='addbtn' onClick={() => addImage()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Image</Button>
                    }</Box>
                <Box className='whiteContainer'>
                    <DataTable />
                </Box>
            </Box>
        </Box>
    )
}