import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../../components/drawer'
import Description from './Desc'
import Sidebar from '../Sidebar'
import Settings_sidebar from '../Sidebar'
import { useLocation, useParams } from 'react-router-dom'

export default function CommunityDesc() {
    
    const params = useParams()
    const location = useLocation()
    console.log(location.state)
    const id  = location.state || {}; 
    console.log(id)
    console.log(params,'paramsparams');
    return (
        <Box className='settings withSubmenu'>
            <MiniDrawer menuOrder={13}/>
            <Sidebar subtype={0} id={id}/>
            <Box component="section" className="contentWraper">
                <Description params={params} id={id}/>
            </Box>
        </Box>
    )
}
