import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './DataTable'

export default function Events() {
    let navigate = useNavigate()
    const auth = JSON.parse(localStorage.getItem("userData"))
    const role = JSON.parse(localStorage.getItem("roledata"))

    const addEvents = () => {
        navigate('/addevents');
    }
    return (
        <Box className='companies'>
            <MiniDrawer menuOrder={3} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Events</Typography>
                    {/* {auth.role_id === 2 && auth.planId.Event && auth.planId.Event.addEvent === true && <Button variant="contained" className='addbtn' onClick={() => addEvents()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Events</Button>} */}
                    {role.UserRoles == null ? <Button variant="contained" className='addbtn' onClick={() => addEvents()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Event</Button>
                        : role.UserRoles.Event.addEvent && <Button variant="contained" className='addbtn' onClick={() => addEvents()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Event</Button>
                    }</Box>
                <DataTable />
            </Box>
        </Box>
    )
}