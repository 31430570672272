import { Box, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import Timeline from './timeline';
import About from './about';
import Photos from './photos';
import Videos from './videos';
import Courses from './courses';
import { useCommunity } from "../Context/CommunityContext";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CommunityTabs({ community_id }) {
    const [value, setValue] = React.useState(0);
    const { communitydetails, fetchCommunity } = useCommunity();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchCommunity(community_id);
    }, [community_id]);


    return (
        <Box className='community_tabs'>
            {/* <Box >
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Timeline" {...a11yProps(0)} />
                   
                </Tabs>
            </Box> */}
            {/* <CustomTabPanel className='tabpanel' value={value} index={0}> */}
                <Timeline communitydetails={communitydetails} />
            {/* </CustomTabPanel> */}
            {/* <CustomTabPanel className='tabpanel' value={value} index={1}>
                <About communitydetails={communitydetails} />
            </CustomTabPanel> */}
            {/* <CustomTabPanel className='tabpanel' value={value} index={2}>
                <Photos communitydetails={communitydetails} />
            </CustomTabPanel> */}
            {/* <CustomTabPanel className='tabpanel' value={value} index={3}>
                <Videos communitydetails={communitydetails} />
            </CustomTabPanel> */}
            {/* <CustomTabPanel className='tabpanel' value={value} index={4}>
                <Courses communitydetails={communitydetails} />
            </CustomTabPanel> */}
        </Box>

    )
}
