import React, { useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import Sidebar from './../../Sidebar'
// import MiniDrawer from '../../../components/drawer'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, MenuItem, Card, CardContent, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, TextField, Select, OutlinedInput, Typography, Input, CircularProgress } from '@mui/material'
import { Close, Message } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/pagination';
import Sidebar from './../../Sidebar'
import MiniDrawer from '../../../../../components/drawer'
import 'swiper/css/navigation';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Edit_Video from './EditVideo'
import Addvideo from "./EditVideo";
import { messaging } from "../../../../../firebase";


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#E80A89',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#E80A89',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Service_Steppyform() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [videos, setVideos] = useState([])
    const [community, setCommunity] = React.useState([])
    const [user, setUser] = React.useState([])
    const params = useParams()
    const [addVideoFrom, setaddVideoFrom] = useState('Teacher')
    const [selectedCard, setSelectedCard] = useState(null);
    const [addonsModal, setAddonsModal] = React.useState(false)
    const [numberOfBlogs, setNumberOfBlogs] = useState('');
    const [open, setOpen] = React.useState(false);
    const [eventAddons, setEventAddons] = React.useState([])
    const redirecturl = process.env.REACT_APP_REDIRECT_URL
    const [error, setError] = React.useState({ message: "" });
    const [loading, setLoading] = useState(false);


    const [imageAddError, setImageAddError] = React.useState('')

    console.log(redirecturl, "redirecturl");

    const [amount] = useState('1000'); // Fixed amount value
    const datas = [
        { sectionName: "Event", amount_type: "INR", amount: "30", count: "3" },
        { sectionName: "Event", amount_type: "INR", amount: "50", count: "5" }
    ];

    const handleNumberOfBlogsChange = (event) => {
        setNumberOfBlogs(event.target.value);
    };


    const handleCardClick = (item) => {
        setSelectedCard(item);
    };

    const handleSubmit = async () => {
        const body = {
            userId: auth._id,
            customerId: auth.stripeCustomerId,
            // AddOns : selectedCard // if selectedCard is an array
            AddOns: [selectedCard],   // [selectedCard] because selectedCard is an object.. If multiple items can be selected push each item into selectedCard.
            redirectUrl: process.env.REACT_APP_REDIRECT_URL + `teachers/details/video/${auth._id}`

        }
        console.log(body, 'bodybodybody')
        await axios.put(process.env.REACT_APP_BASE_URL + `subscription/UpdateAddOnsByUserid`, body, { headers: headerlocal })
            .then(res => {
                console.log(res.data?.PaymentLink, "update response console")
                window.open(res.data?.PaymentLink)
                onClose();
            })
            .catch(err => {
                console.log(err, "UpdateAddOnsByUserid catch error")
            })
    };


    const handleClose = () => {
        setOpen(false);
    };


    const handlePurchaseAddons = async () => {
        var someData = auth ? auth.planId?.addOns?.filter(item => item.sectionName == 'Videos') : [] //display only events addons
        //var someData = auth ? auth.planId?.addOns : [] // display all addons
        setEventAddons(someData)
        setAddonsModal(true)
        setOpen(false)
    }

    const onClose = () => {
        setAddonsModal(false)
    }

    const auth = JSON.parse(localStorage.getItem("userData"))

    const validatenew = () => {
        if (videos.length <= 0) {
            setError({ message: "These fields are required." })
            return false
        }
        if (!videos[0].imgraw || !videos[0].vdoraw) {
            setError({ message: "These fields are required." })
            return false
        }
        return true
    }

    React.useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getUserdata`, { headers: headerformdata });
            setUser(response.data.getuserdata);
            console.log(response.data, 'response.data.user');
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    React.useEffect(() => {
        getCommunity()
    }, [])

    const getCommunity = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityList`, { headers: headerformdata });
            setCommunity(response.data.community);
            console.log(response.data, 'response.data.community');
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [selecttedUsers, setSelecttedUsers] = React.useState([])
    const [userName, setUserName] = React.useState([])
    const handleSelectUser = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setUserName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getArrayval()
    }
    React.useEffect(() => {
        getArrayval()
    }, [userName])
    function getArrayval() {
        var array = []
        const selectedUser = user.filter(content => userName.includes(content.username));
        //console.log(selectedUser, 'selectedUser');
        selectedUser.map((item, i) => {
            var val = { user_id: item._id }
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedUsers(array)
    }
    console.log(selecttedUsers, "selecttedUsersselecttedUsersselecttedUsers");

    const [selecttedCommunity, setSelecttedCommunity] = React.useState([])
    const [coummunityName, setCommunityName] = React.useState([])
    const handlechangeCommunity = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setCommunityName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getCommunityval()
    }
    React.useEffect(() => {
        getCommunityval()
    }, [coummunityName])

    function getCommunityval() {
        var array = []
        const selectedCommunity = community.filter(content => coummunityName.includes(content.coummunity_name));
        // console.log(selectedCourse,'selectedCourseselectedCourse');
        selectedCommunity.map((item, i) => {
            var val = item._id
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedCommunity(array)
    }
    console.log(selecttedCommunity, "selecttedCourseselecttedCourse");

    const handleAddVideo = () => {
        var valid = validatenew();
        if (!valid) return;
        const imgrawArray = videos.map(video => video.imgraw);
        const vdorawArray = videos.map(video => video.vdoraw);
        console.log(valid)

        if (valid === true) {
            const formData = new FormData();
            // const idArray = selecttedUsers.map(id => id.user_id);
            formData.append('createdby[]', params && params.id);
            formData.append('addVideoFrom', addVideoFrom)
            // const idArrayCommunities = selecttedCommunity.map(id => id);
            // idArrayCommunities.forEach(id => {
            //     formData.append('community_id[]', id); // Use 'community_id[]' to send as array
            // });
            // formData.append('user_id', JSON.stringify(selecttedUsers.length > 0 ? selecttedUsers : []))
            // Assuming selecttedUsers is an array of user IDs
            // let usersData = selecttedUsers.map(userId => ({ user_id: userId }));
            setLoading(true);

            { imgrawArray ? imgrawArray.forEach((item) => { formData.append("thumbnail", item) }) : formData.append("thumbnail", '') }
            { vdorawArray ? vdorawArray.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '') }
            console.log(...formData, "formDataformData");
            axios.post(process.env.REACT_APP_BASE_URL + `video/addVideo`, formData, { headers: headerformdata })
                .then((res) => {
                    navigate(`/teachers/details/video/${params.id}`);
                    enqueueSnackbar("Video added successfully", { variant: 'success' })
                    console.log(res, "added");
                })
                .catch((err) => {
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
                    console.log(err, "error");
                    if (err.response.data?.status == 3) {
                        setOpen(true)
                    }
                })
                .finally(() => {
                    setLoading(false); // Stop loading
                });
        }
    }

    const handleparent = (value) => {
        setError({ message: "" })
        const newData = value.map(obj => ({
            "imgraw": obj.imgraw,
            "vdoraw": obj.vdoraw
        }));
        setVideos(newData);

    }

    return (
        // <Box className=''>
        <Box className={auth.role_id === 1 ? 'withSubmenu' : ''}>
            {auth.role_id === 1 ? <MiniDrawer menuOrder={2} submenuOrder={1} />
                : <MiniDrawer menuOrder={7} />}
            {auth.role_id === 1 ? <Sidebar subtype={4} /> : ''}
            <Box component="section" className="contentWraper">
                <Box className='whiteContainer p-20'>
                    <Typography component='h6' className='formTitle mb-20'>Add Video</Typography>
                    <Box className="mb-20">

                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <label className='form-labels label mb-10' for="my-input" >Videos <Typography component='span' className='star'> * </Typography></label>
                            <Box className="" style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                                <Addvideo parentFunc={handleparent} errorObj={error} style={{ marginBottom: "15px" }} />
                                {error.message && <span className="error-message" style={{ color: "red", marginTop: "15px" }}>{error.message}</span>}
                            </Box>
                        </Grid>



                        <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                            {
                                loading ? <Button onClick={handleAddVideo} variant="contained" className='theme-btn' disabled>Loading...</Button>
                                    : <Button onClick={handleAddVideo} variant="contained" className='theme-btn'>Submit</Button>

                            }
                        </Box>


                        {/* <div>
      <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
        <Button onClick={handleAddVideo} variant="contained" className='theme-btn' disabled={loading}>
          Submit
        </Button>
      </Box>
      {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </div> */}
                    </Box>

                </Box >
                <Dialog open={addonsModal} onClose={onClose} maxWidth="sm" fullWidth>
                    <DialogTitle>Select Details</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {eventAddons && eventAddons.length > 0 && eventAddons.map((item, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': { boxShadow: 3 },
                                            border: selectedCard?.count === item?.count ? '2px solid #1976d2' : '1px solid #e0e0e0'
                                        }}
                                        onClick={() => handleCardClick(item)}
                                    >
                                        <CardContent>
                                            <Typography variant="h6">{item.sectionName}</Typography>
                                            <Typography variant="body1">Amount Type: {item.amount_type}</Typography>
                                            <Typography variant="body1">Amount: {item.amount}</Typography>
                                            <Typography variant="body1">Count: {item?.count}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} color="primary" variant="contained" disabled={!selectedCard}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={open} keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    className='custom_modal'>
                    <DialogTitle>Would you like to purchase Video Addons?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose} className='cancel_btn'>No</Button>
                        <Button onClick={handlePurchaseAddons} className='theme-btn'>Yes</Button>
                    </DialogActions>
                </Dialog>
            </Box >
        </Box>
    )
}

export default Service_Steppyform;
