import React, { useEffect, useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Sidebar from '../Sidebar'
import MiniDrawer from '../../../../components/drawer'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, MenuItem, Checkbox, ListItemText, TextField, Select, OutlinedInput, Typography, Input } from '@mui/material'
import { Close } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#E80A89',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#E80A89',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Service_Steppyform() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const params = useParams()
    const [state1, setState1] = React.useState([]);
    const [state2, setState2] = React.useState([]);
    const [imageAddError, setImageAddError] = React.useState('');
    const [community, setCommunity] = React.useState([])
    const [user, setUser] = React.useState([])
    const [addImageFrom, setaddImageFrom] = useState('Community');

    const [communityData, setCommunityData] = useState(null);

	useEffect(() => {
        // Retrieve and parse community data from local storage when the component mounts
        const communityDataString = localStorage.getItem('communityData');
        if (communityDataString) {
            setCommunityData(JSON.parse(communityDataString));
        }
    }, []);

	console.log(params, 'paramschild1');
	console.log(communityData)

    const multipleImgChange = (e, index) => {
        setImageAddError('');

        // Validate file size
        const maxSize = 1024 * 1024; // 1MB in bytes
        const files = e.target.files;
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > maxSize) {
                // Handle file size error (for example, set an error state)
                setImageAddError('Image size should be less than 1 MB.');
                continue; // Skip this file
            }

            fileObj.push(files[i]);
            fileArray.push(URL.createObjectURL(files[i]));
            filepath.push(files[i]);
        }

        var data = state1.filter(x => x != null);
        var data1 = state2.filter(x => x != null);
        setState1(data);
        setState2(data1);
        setState1(prevValue => prevValue.concat(fileArray));
        setState2(prevValue => prevValue.concat(filepath));
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }

    const handleAddNews = () => {
        var multipleImage = state2.filter(x => x != null);
        var valid = validatenew();

        if (valid === true) {
            const formData = new FormData();
            formData.append('createdby[]', communityData?._id);
            formData.append('addImageFrom', addImageFrom)
            formData.append('community_id[]', communityData?._id)

            
            multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '') 
            
            // formData.append('community_id', JSON.stringify(selecttedCommunity.length > 0 ? selecttedCommunity : []))
            // formData.append('user_id', JSON.stringify(selecttedUsers.length > 0 ? selecttedUsers : []))
            // Assuming selecttedUsers is an array of user IDs

            console.log(...formData, "image");
            axios.post(process.env.REACT_APP_BASE_URL + `image/addimage`, formData, { headers: headerformdata })
                .then((res) => {
                    navigate(`/community/community-details/imagestable/${params.id}`);
                    setState1([]);
                    setState2([]);
                    enqueueSnackbar("Additional images added successfully", { variant: 'success' })
                    console.log(res, "added");
                })
                .catch((err) => {
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
                    console.log(err, "error");
                })
        }
    }

    const validatenew = () => {
        if (state2?.length === 0) {
            setImageAddError("This field is required.")
            return false
        }
        return true
    }

    return (
        <Box className='withSubmenu'>
            <MiniDrawer menuOrder={13} />
            <Sidebar subtype={7} />
            <Box component="section" className="contentWraper">
                <Box className='whiteContainer p-20'>
                    <Typography component='h6' className='formTitle'>Images</Typography>

                    <Box className="mt-20 mb-10">
                        <Box className=" mb-10">
                            <Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">
                                <Grid item xs={12} sm={12} md={12}  >
                                    <label className='form-labels label' for="my-input" >Additional Images<Typography component='span' className='star'> * </Typography></label>
                                    <Box className="mb-10 mt-10 d-flex justify-content-between flex-wrap align-items-center">
                                        {state1.length < 1 &&
                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
                                            </Button>
                                        }
                                    </Box>
                                </Grid>


                                <Grid item xs={12} sm={12} md={12}  >
                                    {state1.length > 0 &&
                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                            {state1.length > 0 ? state1.map((x, i) => (
                                                // <Grid item xs={6} sm={3} md={3} lg={3} >
                                                    <Box className='uploaded_img mr-10 mb-20' >
                                                        <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                        <img src={x} alt=''  />
                                                    </Box>
                                                // </Grid>
                                            )) : ""}
                                            {/* <Grid item xs={6} sm={3} md={3} lg={3} > */}
                                                <label htmlFor="contained-button-file1" className="minw200">
                                                    <Box className='multiple_upload_btn' >
                                                        <Input
                                                            inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                            onChange={multipleImgChange}
                                                            style={{ display: "none", }}
                                                            id="contained-button-file1"
                                                            type="file"
                                                        />
                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                                                    </Box>
                                                </label>
                                            {/* </Grid> */}
                                        </Grid>
                                    }
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                                    {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>

                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                        <Button onClick={handleAddNews} variant="contained" className='theme-btn'>Submit</Button>
                    </Box>

                </Box >
            </Box >
        </Box >
    )
}

export default Service_Steppyform;
