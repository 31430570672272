import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, Button, TextField, InputAdornment, Card, Menu, MenuItem } from '@mui/material';
import { SearchOutlined, Visibility as VisibilityIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import DOMPurify from 'dompurify';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import Community from '../../Teachers/Submodule/Community';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate();
    const params = useParams()
    const { enqueueSnackbar } = useSnackbar();
    const [newdata, setNewdata] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null); // State to store the ID of image to delete
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState({});
    const [alldata, setAlldata] = React.useState('');
    const [catid, setCatId] = React.useState('all');
    const [deleteImagePath, setDeleteImagePath] = React.useState(null);

    const [communityData, setCommunityData] = React.useState(null);

	React.useEffect(() => {
        // Retrieve and parse community data from local storage when the component mounts
        const communityDataString = localStorage.getItem('communityData');
        if (communityDataString) {
            setCommunityData(JSON.parse(communityDataString));
        }
    }, []);

	console.log(params, 'paramschild1');
	console.log(communityData)

    React.useEffect(() => {
        getNews();
    }, [rowsPerPage, page, search, catid]);

    const getNews = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `image/Getimagebycommunityid/${communityData?._id}`, { headers: headerlocal });
            console.log(response.data, "Image Data data");
            setNewdata(response.data.getCommunityimage);

        } catch (error) {
            console.error("Error fetching Images:", error);
            enqueueSnackbar("Error fetching Images", { variant: 'error' });
        }
    }

    const handleDeleteImage = async (imagePath) => {
        const body = {
            imgpath: imagePath
        };

        try {
            await axios.put(
                `${process.env.REACT_APP_BASE_URL}image/deleteImage/${deleteId}`,
                body,
                { headers: headerlocal }
            );
            enqueueSnackbar("Image deleted successfully", { variant: 'success' });
            getNews(communityData?._id);
            handleClose();
        } catch (error) {
            console.error("Error deleting image:", error);
            enqueueSnackbar("Error deleting image", { variant: 'error' });
        }
    };




    const handleClick = (event, data) => {
        setAlldata(data);
        setAnchorEl((prev) => ({
            ...prev,
            [data._id]: event.currentTarget
        }));
    };

    const handleClose_menu = (id) => {
        setAnchorEl((prev) => ({
            ...prev,
            [id]: null
        }));
    };

    // const handleView = () => {
    //     setAnchorEl(null);
    //     setOpenview(true);
    // }

    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/editnews", { state: val });
    }

    const handleClickOpen = (id) => {
        setAnchorEl(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        setCatId('all');
        setSearch('');
        setPage(0);
    }

    const sanitizedContent = DOMPurify.sanitize(alldata.description, { ALLOWED_TAGS: ['img'] });

    // const handleClickClose = () => {
    //     setOpenview(false);
    //     setAlldata('');
    // }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeleteDialogOpen = (id, imagePath) => {
        setDeleteId(id); // Set the _id of the image to delete
        setDeleteImagePath(imagePath); // Set the image path
        setOpen(true); // Open the delete dialog
    };



    const handleDeleteDialogClose = () => {
        setDeleteId(null);
        setOpen(false);
    };

    return (
        <>
            <Box className='whiteContainer p-20 communityDet_Images'>
                <Box className='flex-center flex-wrap mb-20 '>
                    {/* <Box component='div' className='DTsearch mr-10'>
                        <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            value={search}
                            className='textfield search '
                            onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                            placeholder='Search Here...'
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                            }}
                        />
                    </Box> */}
                </Box>
                <Box>
                    {newdata && newdata.length > 0 ? newdata.map((data, index) => (
                        <Grid container columnSpacing={2} rowSpacing={2} className="">
                            {/* Render images side by side here */}
                            {data && data.additionalimages && data.additionalimages?.length > 0 && data.additionalimages.map((tdata, i) => (
                                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3}>
                                    <Card key={i} className="imageContainer position_rel">
                                        <img
                                            width={'100%'}
                                            height={250}
                                            src={process.env.REACT_APP_BASE_URL + `${tdata}`}
                                            style={{ marginRight: '5px' }}
                                            alt={`Image ${i}`}
                                        />
                                        <IconButton onClick={() => handleDeleteDialogOpen(data._id, tdata)} className="vertical_menu">
                                            <DeleteIcon className='fz-18'/>
                                        </IconButton>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    )) : (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body1" className='text-center semibold'>No data found</Typography>
                        </Grid>
                    )}

                    <Dialog
                        open={open}
                        keepMounted
                        onClose={handleDeleteDialogClose}
                        aria-describedby="alert-dialog-slide-description"
                        className='custom_modal'
                    >
                        <DialogTitle>Would you like to delete this image?</DialogTitle>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogClose} className='cancel_btn'>No</Button>
                            <Button onClick={() => handleDeleteImage(deleteImagePath)} className='theme-btn'>Yes</Button>

                        </DialogActions>
                    </Dialog>

                </Box>
                {/* <TablePagination
                    rowsPerPageOptions={[8, 12, 16, 20, 24]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                /> */}
            </Box>
        </>
    );
}
